import { Link } from "gatsby";
import React from "react";
// import Img from "gatsby-image";
import * as styles from "./ProductItem.module.scss";

const ProductItem = ({ product }) => {
  return (
    <div className={styles.container}>
      <Link to={`/san-pham/${product.id}`}>
        {product.images[0].url && (
          <img className={styles.img} src={product.images[0].url} />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2 className={styles.name}>{product.product_name}</h2>
          <p className={styles.price}>
            {!product.available ? "Tạm hết hàng" : product.price}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ProductItem;
