const refreshProductData = async () => {
  await fetch("https://znapi.herokuapp.com/api/products?token=tokenznapi")
    .then((res) => res.json())
    .then(({ data }) => {
      window.localStorage.setItem("products", JSON.stringify(data));
    });
};

const useProducts = async (shouldRefresh = true) => {
  if (shouldRefresh) {
    await refreshProductData();
  }
  const products = window.localStorage.getItem("products");
  if (products) {
    return JSON.parse(products);
  }
  return await useProducts(false);
};

export default useProducts;
