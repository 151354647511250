import React from "react";
import PropTypes from "prop-types";

import Seo from "../Seo/Seo";
import ContactBar from "../ContactBar/ContactBar";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Layout.module.scss";

const Layout = ({ children, title, description, keywords = [] }) => (
  <div className={styles.container}>
    <Seo
      title={title}
      description={description}
      keywords={[
        ...keywords,
        "zn audio",
        "linh kiện tụ điện",
        "tụ điện tango",
        "ayaka shop",
        "tamura",
      ]}
    />
    <ContactBar />
    <div className={styles.body}>{children}</div>
    <div className={styles.fixedContact}>
      <a href="tel:+84903628212">
        <StaticImage
          placeholder="blurred"
          alt="top website"
          className={styles.icon}
          src="../../images/phone-filled.png"
        />
      </a>
      <a href="#top">
        <StaticImage
          className={styles.icon}
          placeholder="blurred"
          alt="top website"
          src="../../images/up-filled.png"
        />
      </a>
    </div>
    <ContactBar />
    {/* <div>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </div> */}
    {/* <Footer /> */}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Layout;
