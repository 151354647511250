import React from "react";
import Button from "../../Button/Button";
import ProductItem from "../ProductItem/ProductItem";
import * as typographyStyles from "../../../styles/typography.module.scss";
import * as styles from "./ProductsGrid.module.scss";

const ProductsGrid = ({
  title,
  products,
  showReadMore,
  categoryHandle,
  withTitleMargin,
  center,
}) => {
  return (
    <div className={styles.container}>
      {title && (
        <h2
          className={`${typographyStyles.title} ${
            withTitleMargin && styles.title
          }`}
        >
          {title}
        </h2>
      )}
      <div
        className={styles.products}
        style={center ? { justifyContent: "center" } : undefined}
      >
        {products &&
          products.map((product, index) => (
            <ProductItem key={index} product={product} />
          ))}
      </div>
      {showReadMore && (
        <div className={styles.readMore}>
          <Button
            text="Xem thêm"
            link={
              categoryHandle
                ? `/cua-hang?phan-loai=${categoryHandle}`
                : "/cua-hang"
            }
          />
        </div>
      )}
    </div>
  );
};

export default ProductsGrid;
