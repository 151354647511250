import React from "react";
import { Link } from "gatsby";
import * as styles from "./Button.module.scss";

const Button = ({ link, text, filled }) => {
  return (
    <div className={`${styles.button} ${filled && styles.filledButton}`}>
      <Link to={link} style={{ textDecoration: "none" }}>
        {text}
      </Link>
    </div>
  );
};

export default Button;
