import React from "react";
import * as styles from "./ContactBar.module.scss";

const ContactBar = ({ light }) => {
  return (
    <div className={styles.wrapper} style={light && { color: "#FFF" }}>
      <a href="tel:+84903628212">0903.62.82.12 Mr. Dân (1954)</a>
      <a href="https://goo.gl/maps/roq9mGiqHdvhSJKm7">
        48/20 Hồ Biểu Chánh, Q.Phú Nhuận, TP.HCM
      </a>
      <a href="mailto:zaan.nguyen@gmail.com">zaan.nguyen@gmail.com</a>
    </div>
  );
};

export default ContactBar;
